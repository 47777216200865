import { useQuery } from 'react-apollo'
import manualProductsRecommendationQuery from '../CustomElements/ManualProductsRecommendationQuery.gql'

function prepareArticleNumbersToFetch(products) {
   if (!Array.isArray(products)) return []

   const extractedArticleNumbersArr = products?.reduce(
      (accumulatedArticleNumbers, item) => {
         const artNr = item?.productNumber
         if (artNr !== null && artNr !== undefined) {
            accumulatedArticleNumbers.push(artNr)
         }
         return accumulatedArticleNumbers
      },
      []
   )
   return extractedArticleNumbersArr
}

export const useHandleHelloRetailProducts = helloRetailProducts => {
   const articleNumbersToFetch =
      prepareArticleNumbersToFetch(helloRetailProducts)

   const { data, error, loading } = useQuery(
      manualProductsRecommendationQuery,
      {
         variables: {
            articles: [...articleNumbersToFetch],
         },
         // Skip query if articleNumbersToFetch is falsy/empty-array
         skip: !articleNumbersToFetch?.length || !articleNumbersToFetch,
      }
   )

   return { data, error, loading }
}

export const HelloRetailToJetshopCarousel = ({
   render,
   products,
   loading: propsLoading,
   ...restProps
}) => {

   // Execute a GraphQL query with the prepared article numbers
   const {
      data,
      error,
      loading: loadingJetShopQuery,
   } = useHandleHelloRetailProducts(products)

   // Combine the loading states from the GraphQL query and the props
   // const isLoading = loadingJetShopQuery || propsLoading;

   // Prepare the results including any additional props and loading states
   const modifiedResults = {
      ...restProps,
      products: data?.products,
      loading: loadingJetShopQuery,
   }

   // Render the component using the provided render prop and the modified results
   return render(modifiedResults)
}
