import React from 'react'
import { styled } from 'linaria/react'
import SiteButton from '../SiteButton'
import { NavArrowRight } from 'iconoir-react'
import Link from '../Link'

const ComponentSeparatorContainer = styled.div`
   .separator-title {
      font-size: calc(1vw + 1.2rem);
   }
   .brand-image {
      height: 2.4rem;
      width: auto;
   }
`

const RightElementHandler = ({ logoSrc, ctaLink, ctaText }) => {
   if (ctaLink && ctaText) {
      return (
         <SiteButton
            style={{ padding: '0.8rem 0.8rem 0.8rem 1.5rem' }}
            href={ctaLink}
            styleType="solid"
            color="blue"
            size="large"
            icon={true}
         >
            {ctaText}
            <NavArrowRight
               strokeWidth={2.0}
               style={{ marginLeft: '1rem' }}
            ></NavArrowRight>
         </SiteButton>
      )
   } else if (logoSrc && ctaLink) {
      return (
         <Link to={ctaLink}>
            <img
               className="brand-image mb-8"
               src={logoSrc}
               alt={'carousel'}
            />
         </Link>
      )
   } else if (logoSrc) {
      return (
         <img
            className="brand-image mb-8"
            src={logoSrc}
            alt={'carousel'}
         />
      )
   } else {
      return <></>
   }
}

const ComponentSeparator = ({ title, children, logoSrc, ctaLink, ctaText }) => {
   const showRigthElement = logoSrc || ctaLink || ctaText

   return (
      <ComponentSeparatorContainer className="row seperator d-flex align-items-center mb-16 mb-32 ">
         {title && (
            <div className="col-auto">
               <h1 className="separator-title">{title}</h1>
            </div>
         )}
         {children && <div className="col-auto">{children}</div>}
         <div className="col d-block">
            <hr />
         </div>
         {showRigthElement && (
            <div className="col-auto d-none d-md-block">
               <RightElementHandler
                  logoSrc={logoSrc}
                  ctaLink={ctaLink}
                  ctaText={ctaText}
               />
            </div>
          )}
      </ComponentSeparatorContainer>
   )
}

export { ComponentSeparator }

const withRightElementProps = WrappedComponent => {
   return ({ title, children, properties }) => {
      const logoSrc = properties?.find(field => field?.name == 'logoSrc')?.value?.string
      const ctaLink = properties?.find(field => field?.name == 'ctaLink')?.value?.string
      const ctaText = properties?.find(field => field?.name == 'ctaText')?.value?.string

      return (
         <WrappedComponent
            logoSrc={logoSrc}
            ctaLink={ctaLink}
            ctaText={ctaText}
            title={title}
         >
            {children}
         </WrappedComponent>
      )
   }
}

// Export the HOC version with prepared props
const ComponentSeparatorHOC = withRightElementProps(ComponentSeparator)
export { ComponentSeparatorHOC }
