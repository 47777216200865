import React, { useState, useEffect, useRef } from 'react'
import { Above, Below } from '@jetshop/ui/Breakpoints'
import { theme } from '../Theme'
import { useKeenSlider } from 'keen-slider/react'
import { styled } from 'linaria/react'
import 'keen-slider/keen-slider.min.css'
import ProductListCard from '../Core/Cards/ProductListCard'
import { useSiteSettingsQuery } from '../../hooks/useSiteSettings'
import { Product_Page_Settings } from '../CustomElements/elementTypes'
import CarouselSkeleton from '../Core/Skeletons/CarouselSkeleton'
import { ComponentSeparatorHOC } from '../Core/Common/ComponentSeparator'
import Link from '../Core/Link'

const CarouselWrapper = styled('div')`
   position: relative;

   .card-wrapper {
      display: flex;
   }

   .carousel-title {
      & h1 {
         font-family: var(--font-industry);
      }
      /* font-size: calc(1vw + 1.2rem); */
      font-size: ${theme.font.size.responsiveHeaderM};
      ${theme.above.lg} {
         font-size: ${theme.font.size.responsiveHeaderS};
      }
   }

   h1 > a.link {
      color: inherit;
      font-family: inherit;
   }
`

const DotsWrapper = styled('div')`
   display: flex;
   padding: 10px 0px;
   margin-top: 20px;
   justify-content: center;

   .dot {
      border: none;
      width: 1.25rem;
      height: 1.25rem;
      background: #dcdcdc;
      border-radius: 50%;
      margin: 0 5px;
      padding: 5px;
      cursor: pointer;
   }

   .dot:focus {
      outline: none;
   }

   .dot.active {
      background: #195178;
   }
`

const ArrowWrapper = styled('div')`
   .arrow {
      width: 26px;
      height: 52px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      -webkit-transform: translateY(-50%);
      fill: #195178;
      cursor: pointer;
   }

   .arrow > path {
      fill: #195178;
   }

   .arrow--left {
      left: 1rem;
   }

   .arrow--right {
      left: auto;
      right: 1rem;
   }

   .arrow--disabled {
      fill: rgba(255, 255, 255, 0.5);
      opacity: 0.3;
      cursor: initial;
   }
`

// const Carousel = ({ products, loading, title, link, ctaLink, ctaText, logoSrc, className }) => {
const Carousel = ({ products, loading, title, properties, hideHeader, className }) => {
   const [currentSlide, setCurrentSlide] = useState(0)
   const [getSetting] = useSiteSettingsQuery()
   const productSettings = getSetting(null, Product_Page_Settings)
   const [loaded, setLoaded] = useState(false)
   const [carouselProducts, setCarouselProducts] = useState(null)

   const carouselRef = useRef(null)

   useEffect(() => {
      if (!loading) {
         setCarouselProducts(products)
      }
   }, [setCarouselProducts, products, loading])

   const productsPerPageLarge = 6
   const productsPerPageMedium = 4
   const productsPerPageSmall = 2

   const [sliderRef, instanceRef] = useKeenSlider({
      initial: 0,
      loop: false,
      mode: 'free-snap',
      breakpoints: {
         '(min-width: 300px)': {
            slides: { perView: 2, spacing: 0 },
         },
         '(min-width: 992px)': {
            slides: { perView: 4, spacing: 0 },
         },
         '(min-width: 1400px)': {
            slides: { perView: 6, spacing: 0 },
         },
      },
      slides: {
         perView: 6,
         spacing: 12,
      },
      slideChanged(slider) {
         setCurrentSlide(slider.track.details.rel)
      },
      created() {
         setLoaded(true)
      },
   })

   // TODO: Pass products, instanceRef, currentSlide into the function ?
   const nextPage = (e, perPage) => {
      const partialPageProductsLength = products.length % perPage
      const partialPageExists = partialPageProductsLength > 0
      const fullPagesTotalLength = products.length - partialPageProductsLength

      // If not on a defaultPerPage-index(currentSlide) store the difference(number)
      const notOnDefaultPageCorrectionLength = currentSlide % perPage

      // Set move to next defaultPage
      let moveTo = currentSlide + (perPage - notOnDefaultPageCorrectionLength)

      // Don't execute move, end of defaultPages, no partialPage exists
      if (!partialPageExists && moveTo == fullPagesTotalLength) {
         return
      }

      // Move to last partial page
      if (partialPageExists && moveTo >= fullPagesTotalLength) {
         moveTo =
            currentSlide +
            (partialPageProductsLength - notOnDefaultPageCorrectionLength)
      }

      e.stopPropagation() || instanceRef.current?.moveToIdx(moveTo)
   }

   // TODO: Pass products, instanceRef, currentSlide into the function ?
   const previousPage = (e, perPage) => {
      const partialPageProductsLength = products.length % perPage
      const fullPagesTotalLength = products.length - partialPageProductsLength
      const partialPageExists = partialPageProductsLength > 0
      const partialPageProductsLastIndex =
         fullPagesTotalLength - perPage + partialPageProductsLength

      // If not on a defaultPerPage-index(currentSlide) store the difference(number)
      const notOnDefaultPageCorrectionLength = currentSlide % perPage

      // If on start-index, exit move(slide-event)
      if (currentSlide == 0) {
         return
      }

      // Set move-index to previous defaultPerPage-index
      let moveTo =
         currentSlide -
         (notOnDefaultPageCorrectionLength > 0
            ? notOnDefaultPageCorrectionLength
            : perPage)

      // If last page is a partialPage, move to previous defaultPerPage-index
      if (partialPageExists && currentSlide == partialPageProductsLastIndex) {
         moveTo = currentSlide - partialPageProductsLength
      }

      e.stopPropagation() || instanceRef.current?.moveToIdx(moveTo)
   }

   const dotClicked = (e, index) => {
      e.stopPropagation() || instanceRef.current?.moveToIdx(index)
   }

   const generateSliderElements = products => {
      const sliders = []
      for (let i = 0; i < products.length; i++) {
         sliders.push(
            <div key={i} className="keen-slider__slide card-wrapper">
               <ProductListCard
                  product={products[i]}
                  productSettings={productSettings}
               ></ProductListCard>
            </div>
         )
      }
      return sliders
   }

   const category = properties?.find(field => field?.name == 'category')?.value

   const titleElement = (
      <>
         {category?.primaryRoute?.path && title ? (
            <h1 className="carousel-title">
               <Link
                  className="link link--vb link--black"
                  to={category?.primaryRoute?.path}
               >
                  {title}
               </Link>
            </h1>
         ) : (
            <h1 className="carousel-title">{title}</h1>
         )}
      </>
   )

   if (!loading && carouselProducts?.length == 0) {
      return <></>
   }

   return (
      <>
         {carouselProducts && carouselProducts?.length > 0 ? (
            <CarouselWrapper
               ref={carouselRef}
               className={`container-fluid m-0 px-128 ${className ?? ''}`}
            >
               { hideHeader !== true && 
               <ComponentSeparatorHOC properties={properties}>
                  {titleElement}
               </ComponentSeparatorHOC>
               }
               <div style={{ marginLeft: '-0.5rem' }}>
                  <div ref={sliderRef} className="keen-slider">
                     {generateSliderElements(carouselProducts)}
                  </div>
               </div>
               {loaded && instanceRef.current && (
                  <>
                     <Above breakpoint="xxl">
                        <ArrowWrapper>
                           <Arrow
                              left
                              onClick={e =>
                                 previousPage(e, productsPerPageLarge)
                              }
                              disabled={currentSlide === 0}
                           />

                           <Arrow
                              onClick={e => nextPage(e, productsPerPageLarge)}
                              disabled={
                                 // If items-length is shorter than defaultPerPage-length
                                 carouselProducts.length <
                                    productsPerPageLarge ||
                                 // If on last possible index
                                 currentSlide ==
                                    carouselProducts.length -
                                       productsPerPageLarge
                              }
                           />
                        </ArrowWrapper>
                     </Above>
                     <Below breakpoint="xxl">
                        <ArrowWrapper>
                           <Arrow
                              left
                              onClick={e =>
                                 previousPage(e, productsPerPageMedium)
                              }
                              disabled={currentSlide === 0}
                           />

                           <Arrow
                              onClick={e => nextPage(e, productsPerPageMedium)}
                              disabled={
                                 // If items-length is shorter than defaultPerPage-length
                                 carouselProducts.length <
                                    productsPerPageMedium ||
                                 // If on last possible index
                                 currentSlide ==
                                    carouselProducts.length -
                                       productsPerPageMedium
                              }
                           />
                        </ArrowWrapper>
                     </Below>
                  </>
               )}

               {loaded && instanceRef.current && (
                  <>
                     <Above breakpoint="xxl">
                        <DotsWrapper>
                           {[
                              ...Array(
                                 instanceRef.current.track.details.slides.length
                              ).keys(),
                           ].map(idx => {
                              if (idx % productsPerPageLarge != 0) {
                                 return (
                                    <React.Fragment key={idx}></React.Fragment>
                                 )
                              }

                              // If items(products)-length is not suitable for default-productsPerPageLarge
                              // this index will be rendered as a partial-page-dot(last page)
                              if (
                                 idx != 0 &&
                                 idx >
                                    carouselProducts.length -
                                       productsPerPageLarge
                              ) {
                                 return (
                                    <button
                                       key={idx}
                                       onClick={e =>
                                          dotClicked(
                                             e,
                                             carouselProducts.length -
                                                productsPerPageLarge
                                          )
                                       }
                                       className={
                                          'dot' +
                                          (currentSlide ===
                                          carouselProducts.length -
                                             productsPerPageLarge
                                             ? ' active'
                                             : '')
                                       }
                                    ></button>
                                 )
                              }

                              return (
                                 <button
                                    key={idx}
                                    onClick={e => dotClicked(e, idx)}
                                    className={
                                       'dot' +
                                       (currentSlide === idx ? ' active' : '')
                                    }
                                 ></button>
                              )
                           })}
                        </DotsWrapper>
                     </Above>
                     <Below breakpoint="xxl">
                        <DotsWrapper className="d-none d-lg-flex">
                           {[
                              ...Array(
                                 instanceRef.current.track.details.slides.length
                              ).keys(),
                           ].map(idx => {
                              if (idx % productsPerPageMedium != 0) {
                                 return (
                                    <React.Fragment key={idx}></React.Fragment>
                                 )
                              }
                              // If items(products)-length is not suitable for default-productsPerPageMedium
                              // this index will be rendered as a partial-page-dot(last page)
                              if (
                                 idx != 0 &&
                                 idx >
                                    carouselProducts.length -
                                       productsPerPageMedium
                              ) {
                                 return (
                                    <button
                                       key={idx}
                                       onClick={e =>
                                          dotClicked(
                                             e,
                                             carouselProducts.length -
                                                productsPerPageMedium
                                          )
                                       }
                                       className={
                                          'dot' +
                                          (currentSlide ===
                                          carouselProducts.length -
                                             productsPerPageMedium
                                             ? ' active'
                                             : '')
                                       }
                                    ></button>
                                 )
                              }

                              return (
                                 <button
                                    key={idx}
                                    onClick={e => dotClicked(e, idx)}
                                    className={
                                       'dot' +
                                       (currentSlide === idx ? ' active' : '')
                                    }
                                 ></button>
                              )
                           })}
                        </DotsWrapper>
                     </Below>
                     <Below breakpoint="lg">
                        <DotsWrapper className="d-lg-none">
                           {[
                              ...Array(
                                 instanceRef.current.track.details.slides.length
                              ).keys(),
                           ].map(idx => {
                              if (idx % 2 != 0) {
                                 return (
                                    <React.Fragment key={idx}></React.Fragment>
                                 )
                              }

                              // If items(products)-length is not suitable for default-productsPerPageSmall
                              // this index will be rendered as a partial-page-dot(last page)
                              if (
                                 idx != 0 &&
                                 idx >
                                    carouselProducts.length -
                                       productsPerPageSmall
                              ) {
                                 return (
                                    <button
                                       key={idx}
                                       onClick={e =>
                                          dotClicked(
                                             e,
                                             carouselProducts.length -
                                                productsPerPageSmall
                                          )
                                       }
                                       className={
                                          'dot' +
                                          (currentSlide ===
                                          carouselProducts.length -
                                             productsPerPageSmall
                                             ? ' active'
                                             : '')
                                       }
                                    ></button>
                                 )
                              }

                              return (
                                 <button
                                    key={idx}
                                    onClick={e => dotClicked(e, idx)}
                                    className={
                                       'dot' +
                                       (currentSlide === idx ? ' active' : '')
                                    }
                                 ></button>
                              )
                           })}
                        </DotsWrapper>
                     </Below>
                  </>
               )}
            </CarouselWrapper>
         ) : (
            <CarouselSkeleton hideHeader={hideHeader}></CarouselSkeleton>
         )}
      </>
   )
}

function Arrow(props) {
   const disabeld = props.disabled ? ' arrow--disabled' : ''
   return (
      <Above breakpoint="lg">
         <svg
            onClick={props.onClick}
            className={`arrow ${
               props.left ? 'arrow--left' : 'arrow--right'
            } ${disabeld}`}
            xmlns="http://www.w3.org/2000/svg"
            viewBox={props.left ? '0 0 11 19' : '0 0 14 23'}
         >
            {props.left && (
               <path
                  d="M0.867189 8.83594C0.515626 9.1875 0.515626 9.77344 0.867188 10.125L8.44531 17.7422C8.83594 18.0937 9.42188 18.0937 9.77344 17.7422L10.6719 16.8437C11.0234 16.4922 11.0234 15.9062 10.6719 15.5156L4.65625 9.5L10.6719 3.44531C11.0234 3.05469 11.0234 2.46875 10.6719 2.11719L9.77344 1.21875C9.42188 0.867187 8.83594 0.867187 8.44531 1.21875L0.867189 8.83594Z"
                  fill="#195178"
               ></path>
            )}
            {!props.left && (
               <path
                  d="M12.916 12.4551C13.3555 12.0156 13.3555 11.2832 12.916 10.8438L3.44336 1.32227C2.95508 0.882812 2.22266 0.882812 1.7832 1.32227L0.660156 2.44531C0.220703 2.88477 0.220703 3.61719 0.660156 4.10547L8.17969 11.625L0.660156 19.1934C0.220703 19.6816 0.220703 20.4141 0.660156 20.8535L1.7832 21.9766C2.22266 22.416 2.95508 22.416 3.44336 21.9766L12.916 12.4551Z"
                  fill="white"
               ></path>
            )}
         </svg>
      </Above>
   )
}

export default Carousel
