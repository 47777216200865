import React from 'react';
import { styled } from 'linaria/react'
import ListCardSkeleton from './ListCardSkeleton';
import { theme } from '../../Theme';
import { Above, Below } from '@jetshop/ui/Breakpoints';


const CarouselSkeletonContainer = styled.div`
   h2 > div {
      width: 50%;
      ${theme.above.md} {
         width: 33%;
      }
      ${theme.above.xl} {
         width: 25%;
      }
   }
`

const CarouselSkeleton = ({ hideHeader }) => {
   return (
      <CarouselSkeletonContainer className="px-128 mb-32">
         <div className="d-none d-xxl-block">
            {hideHeader !== true && (
               <h2 className="mb-32 ">
                  <div className="placeholder"></div>
               </h2>
            )}
             <div className="d-none d-xxl-flex gap-2 ">
                <ListCardSkeleton></ListCardSkeleton>
                <ListCardSkeleton></ListCardSkeleton>
                <ListCardSkeleton></ListCardSkeleton>
                <ListCardSkeleton></ListCardSkeleton>
                <ListCardSkeleton></ListCardSkeleton>
                <ListCardSkeleton></ListCardSkeleton>
             </div>
          </div>
          <div className="d-block d-xxl-none">
             <h2 className="mb-32 ">
                <div className="placeholder"></div>
             </h2>
             <div className="d-none d-lg-flex gap-2">
                <ListCardSkeleton></ListCardSkeleton>
                <ListCardSkeleton></ListCardSkeleton>
                <ListCardSkeleton></ListCardSkeleton>
                <ListCardSkeleton></ListCardSkeleton>
             </div>
             <div className="d-flex d-lg-none gap-2 ">
                <ListCardSkeleton></ListCardSkeleton>
                <ListCardSkeleton></ListCardSkeleton>
             </div>
          </div>
       </CarouselSkeletonContainer>
    )
}
 
export default CarouselSkeleton;