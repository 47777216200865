import ChannelContext from '@jetshop/core/components/ChannelContext';
import React, {useEffect, useState, useContext } from 'react'
import { fetchHelloRetailRecoms } from './Api';
import { getHelloRetailChannelConfigs, getHelloRetailTrackingId } from './HelloRetailHelper';

export function UseHelloRetail(keys, hierarchies) {
    const [helloRetailRecoms, setHelloRetailRecoms] = useState(null);
    const selectedChannel = useContext(ChannelContext)?.selectedChannel
    const [loading, setLoading] = useState(true);
    const config = getHelloRetailChannelConfigs(selectedChannel?.id);

    const fetchRecoms = async () => {
        const trackingUserId = await getHelloRetailTrackingId(config?.id)
        let res = await fetchHelloRetailRecoms(config, trackingUserId, keys, hierarchies);
 
        setHelloRetailRecoms(res.responses);
        setLoading(false)
    }

    useEffect(() => {
        const hasKeyValues = keys?.length > 0 && keys[0]?.value;
        // If hierarchies is an array wait for it to be populated, the useffect will be called again when it is populated
        const hasHierarchies = hierarchies?.length > 0;
         // If no hierarchies are passed, fetch recommendations without hierarchies
        const fetchWithoutHierarchies = hierarchies === undefined;

        if(hasKeyValues && (hasHierarchies || fetchWithoutHierarchies)){
            fetchRecoms();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(keys), JSON.stringify(hierarchies)]);

    return { helloRetailRecoms: helloRetailRecoms, helloRetailLoading: loading };
}
